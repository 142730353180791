// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-console-tsx": () => import("./../../../src/pages/console.tsx" /* webpackChunkName: "component---src-pages-console-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-email-refusal-tsx": () => import("./../../../src/pages/email_refusal.tsx" /* webpackChunkName: "component---src-pages-email-refusal-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-policy-privacy-tsx": () => import("./../../../src/pages/policy/privacy.tsx" /* webpackChunkName: "component---src-pages-policy-privacy-tsx" */),
  "component---src-pages-policy-service-tsx": () => import("./../../../src/pages/policy/service.tsx" /* webpackChunkName: "component---src-pages-policy-service-tsx" */)
}

